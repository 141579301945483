import { withRequiredAuthInfo } from "@propelauth/react";
import type { NextPage } from "next";
import { useRouter } from "next/router";
import LoadingGif from "../components/LoadingGif";
import {
  setAmplitudeUserId,
  setAmplitudeUserProperties,
  startAmplitudeTracking,
} from "../utils/amplitude";

const Root = (props: any) => {
  const router = useRouter();
  const { user, isLoggedIn, orgHelper } = props;

  if (!user)
    router.push(process.env.PROPEL_AUTH_URL ?? "https://auth.tennr.com");

  if (isLoggedIn) {
    const orgId = orgHelper.getOrgs()[0]?.orgId;
    const org = orgHelper?.getOrgs()[0];
    if (orgId) {
      router.push(`/home/${orgId}`);
    }
    return <LoadingGif />;
  } else {
    router.push(process.env.PROPEL_AUTH_URL ?? "https://auth.tennr.com");
    return <LoadingGif />;
  }
};

function withCheckedPermissions(
  WrappedComponent: NextPage<any>,
  minRoleRequired: string
) {
  const RerouteWrapper: NextPage<any> = (props) => {
    const router = useRouter();
    const { user, orgHelper, accessHelper } = props;
    const orgId = orgHelper?.getOrgs()[0]?.orgId;
    const org = orgHelper?.getOrgs()[0];
    setAmplitudeUserId(user.userId);

    // Determine the user's role, brute force style
    let currentRole = "Member";
    if (accessHelper.isRole(orgId, "Tennr Developer"))
      currentRole = "Tennr Developer";
    if (accessHelper.isRole(orgId, "Developer")) currentRole = "Developer";
    if (accessHelper.isRole(orgId, "Owner")) currentRole = "Owner";
    if (accessHelper.isRole(orgId, "Admin")) currentRole = "Admin";

    setAmplitudeUserProperties({
      email: user.email,
      name: user.firstName + " " + user.lastName,
      org_name: org?.orgName,
      team_id: orgId,
      role: currentRole,
    });
    startAmplitudeTracking(user.email.includes("tennr"));
    if (accessHelper?.isAtLeastRole(orgId, minRoleRequired)) {
      return <WrappedComponent {...props} />;
    } else {
      router.push(`/run`);
      return <LoadingGif />;
    }
  };
  return RerouteWrapper;
}

export const PermissionsCheckWrapper = (
  page: NextPage<any>,
  minRoleRequired: string = "Owner"
) => withCheckedPermissions(page, minRoleRequired);

export default withRequiredAuthInfo(PermissionsCheckWrapper(Root), {
  // Insert a loading component here
  displayWhileLoading: <LoadingGif />,
});
