import Head from "next/head";
import Image from "next/image";

function LoadingGif() {
  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center">
      <Head>
        <link rel="preload" as="image" href="/Tennr_Loader.gif" />
      </Head>
      <Image
        className="bg-white"
        src={"/Tennr_Loader.gif"}
        height={160}
        width={160}
        alt="loader"
      />
    </div>
  );
}

export default LoadingGif;
